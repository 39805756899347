// import './component/jquery.plugin.colissimo.min'
import './_menu'
import './_testimony'
import './_popupNewsletter'
import './_hpanim'
import './_taxon'
import Colissimo from "./_colissimo_relais";
import CartUpdate from "./_cart_update";
import $ from "jquery";
import CalculLivraison from "./_livraison_calcul";
let colissimo = new Colissimo();
let cartUpdate = new CartUpdate();
let calculLivraison = new CalculLivraison();
window.triggerWidgetCallBack = function (point) {
    colissimo.triggerWidgetCallBack(point);
}
// import './component/mapbox-gl'
