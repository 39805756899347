// let popup = require('semantic-ui-css/components/popup')
import Glide from "@glidejs/glide";
// import $ from "jquery";

let resizePopup = function(){$('.ui.popup').css('max-height', $(window).height());};
$(window).resize(function(e){
    resizePopup();
});

$(document).ready(() => {
    if(document.getElementById('logoAnoq')) {
        // console.log(popup)
        $('.cart.button')
            .popup({
                popup: $('.cart.popup'),
                on: 'click',
                position: 'top right',
                lastResort: 'bottom right',
                offset: 50,
                onShow: function(){
                    resizePopup();
                },
            });
        let image = document.querySelectorAll('.glide__slides__hp img');
        let container = document.getElementById('topglide');
        if (image.length > 0 && container) {
            var glide = new Glide('#topglide', {
                type: 'carousel',
                perView: 1,
                focusAt: 'center',
                autoplay: 5000,
            });
            glide.mount();
        }
        let targetDiv = $('header');
        let targetDivMessage = $('#menu');
        let windowposInit = $(window).scrollTop();
        if (windowposInit >= 11) {
            targetDiv.addClass('scrolling-active');
            targetDivMessage.addClass('scrolling-active');
            if(screen.width < 768){
                document.querySelector(".header-sticky-nav").style.top = "14%"
            }
        }
        else {
            targetDiv.removeClass('scrolling-active');
            targetDivMessage.removeClass('scrolling-active');
            if(screen.width < 768){
                document.querySelector(".header-sticky-nav").style.top = "26%"
            }
        }
        $('#button-header-search').click(function () {
            if($(this).parent().find('input')[0].classList.contains('hidden')){
                $(this).parent().find('input')[0].classList.remove('hidden');
                $(this).parent().find('input')[0].classList.add('open');
            }else{
                $(this).parent().find('input')[0].classList.remove('open');
                setTimeout(() => {
                    $(this).parent().find('input')[0].classList.toggle('hidden');
                },800)
            }
        })
        $('#button-header-search-bar').keyup(function (e) {
            if($(this).is(":focus") && e.keyCode === 13) {
                let search = $(this).val();
                if(window.location.pathname.split('/')[1] === "fr_FR")
                    window.location.href = '/'+window.location.pathname.split('/')[1]+'/catalogue?criteria[search][value]='+search;
                else
                    window.location.href = '/'+window.location.pathname.split('/')[1]+'/catalog?criteria[search][value]='+search;

            }
        });
        $(window).scroll(function () {
            var windowpos = $(window).scrollTop();
            let menu = $('#header-sticky-nav')[0];
            let button = $('#button-header-burger')[0];
            if (windowpos >= 11) {
                targetDiv.addClass('scrolling-active');
                targetDivMessage.addClass('scrolling-active');
                if(screen.width < 768){
                    document.querySelector(".header-sticky-nav").style.top = "7%"
                }
            } else {
                button.classList.contains('-open') ? button.classList.remove('-open'):null;
                menu.classList.contains('-open') ? menu.classList.remove('-open'):null;
                targetDiv.removeClass('scrolling-active');
                targetDivMessage.removeClass('scrolling-active');
                if(screen.width < 768){
                    document.querySelector(".header-sticky-nav").style.top = "17%"
                }
            }

        });
        $('#close').click(function () {
            let menu = $('#header-sticky-nav')[0];
            let button = $('#button-header-burger')[0];
            // let body = $('.pusher')[0];
            button.classList.contains('-open') ? button.classList.remove('-open') : null;
            menu.classList.contains('-open') ? menu.classList.remove('-open') : null;
            // body.classList.contains('-menuOpen')?body.classList.remove('-menuOpen'):null;
        });
        $('#button-header-burger').click(function () {
            let menu = $('#header-sticky-nav')[0];
            let body = $('body')[0];
            body.classList.contains('stop-scrolling')?body.classList.remove('stop-scrolling'):body.classList.add('stop-scrolling');
            this.classList.contains('-open') ? this.classList.remove('-open') : this.classList.add('-open');
            menu.classList.contains('-open') ? menu.classList.remove('-open') : menu.classList.add('-open');
            // body.classList.contains('-menuOpen')?body.classList.remove('-menuOpen'):body.classList.add('-menuOpen');
        });
    }
});
