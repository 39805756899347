import $ from "jquery";

export default class CalculLivraison {
    constructor() {
        this.state = {
            total: $('#sylius-summary-grand-total'),
            expedition: $('#sylius-summary-shipping-total')
        }
        $(document).ready(() => {
            this.init();
        })
    }

    init() {
        if (!this.state.total || this.state.expedition.length === 0)
            return;
        if($('form[name="sylius_checkout_select_shipping"]').length > 0) {
            let val = parseInt($('input[type=radio]:checked')[0].dataset.fee);
            // this.updateTotal(0, val);
            $(document).on('mousedown', 'input[type=radio] + label', (item) => {
                var prevVal = parseInt($('input[type=radio]:checked')[0].dataset.fee);
                var val = parseInt($(item.target).parent().find('input[type=radio]')[0].dataset.fee);
                this.updateTotal(prevVal, val);
            });
            let default_val = parseInt($('input[type=radio]:checked')[0].dataset.fee);
            this.updateTotal(0, default_val);
        }
    }

    updateTotal(pastVal,val) {
        let total = parseFloat($('#sylius-summary-grand-total').text().replace(/\s/g, '').replace('€','').replace(',',''));
        total -= pastVal;
        total += val;
        console.log(this.state.expedition.text())
        this.state.expedition.text((val/100).toFixed(2).toString().replace('.',',') + ' €')
        this.state.total.text((total/100).toFixed(2).toString().replace('.',',') + ' €');
    }
}
