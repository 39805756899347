if(screen.width > 768) {
    if (document.querySelector(".concept-block-container")) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add("-inViewConcept");
                }
            })
        }, {
            rootMargin: '-50%'
        })
        observer.observe(document.querySelector(".concept-block-container"));
        // document.querySelectorAll(".concept-block-image").forEach(function(item,i){
        //     console.log(item);
        //     observer.observe(item);
        // })
    }

    if (document.querySelector(".articles-track")) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add("-inViewArticles");
                }
            })
        }, {
            rootMargin: '-30%'
        })
        observer.observe(document.querySelector(".articles-track"));
        // document.querySelectorAll(".concept-block-image").forEach(function(item,i){
        //     console.log(item);
        //     observer.observe(item);
        // })
    }
}