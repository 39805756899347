import $ from 'jquery'
import axios from "axios";

export default class Colissimo {
    constructor() {
        this.state = {
            "tokenUrl": "/api/v2/custom/token",
            "updateAdressUrl": "/api/v2/custom/address/{id}/update",
            "url_serveur": 'https://ws.colissimo.fr',
            "token": null,
            "ceAddress": null,
            "ceZipCode": null,
            "ceTown": null,
            "selector": null
        }
        $(document).ready(() => {
            this.init();
        })
    }

    init() {
        let input = $('input[value^="COLISSIMO_POINT_RELAIS"]');
        if (input.length < 1)
            return;
        let form = $('form[name="sylius_checkout_select_shipping"]');
        let order = JSON.parse($('#monIdDeWidgetColissimo')[0].dataset.order);
        let address = JSON.parse($('#monIdDeWidgetColissimo')[0].dataset.address);
        let inputs = $('input[type=radio][name="' + input[0].name + '"]');
        let edit = $('.editPR');
        this.state.ceAddress = address.ceAddress;
        this.state.ceZipCode = address.ceZipCode;
        this.state.ceTown = address.ceTown;
        this.state.updateAdressUrl = this.state.updateAdressUrl.replace('{id}', order.id)
        form.on('submit', (ev) => {
            let selected = form.find('input[type=radio]:checked');
            if (selected[0].value.match('^COLISSIMO_POINT_RELAIS')) {
                if ($('#selectedRelais').length < 1) {
                    form[0].classList.remove('loading');
                    alert('Veuillez sélectionner un point relais');
                    ev.preventDefault();
                    return false;
                }
            }
            return true;
        });
        $('#monIdDeWidgetColissimo').on('DOMSubtreeModified', (params) => {
            if (params.target == $('#monIdDeWidgetColissimo')[0]) {
                console.log(params.target.innerHTML === "")
                if (params.target.innerHTML === "") {
                    $('body')[0].classList.remove('-active-shadow');
                    $('div.shadow')[0].classList.remove('-active');
                } else {
                    $('body')[0].classList.contains('-active-shadow') ? null : $('body')[0].classList.add('-active-shadow');
                    $('div.shadow')[0].classList.contains('-active') ? null : $('div.shadow')[0].classList.add('-active');
                }
            }
        })
        inputs.change((er) => {
            if (er.target.value.match('^COLISSIMO_POINT_RELAIS')) {
                this.initWidget();
            }
        })
        edit.click((er) => {
            this.initWidget();
        })
        inputs.each((er, item) => {
            if (item.value.match('^COLISSIMO_POINT_RELAIS'))
                this.state.selector = item;
        })
        if (input[0].checked)
            this.initWidget();
    }

    initWidget() {

        axios.post(this.state.tokenUrl)
            .then((er) => {
                this.state.token = JSON.parse(er.data).token
                this.triggerWidget();
            })
    }

    triggerWidgetCallBack(point) {
        $('body')[0].classList.remove('-active-shadow');
        $('div.shadow')[0].classList.remove('-active');
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        console.log(point);
        axios.post(this.state.updateAdressUrl, {
            "street": point.adresse1,
            "postcode": point.codePostal,
            "city": point.localite
        }, config)
            .then(() => {
                if ($('#selectedRelais').length > 0)
                    $('#selectedRelais')[0].innerHTML = point.nom + ", " +point.adresse1 + ", " + point.codePostal + " " + point.localite;
                else {
                    let header = $(this.state.selector.parentNode.parentNode.parentNode).find('div.content a.header');
                    let br = document.createElement('br')
                    let span = document.createElement('span');
                    span.id = "selectedRelais";
                    span.innerHTML = point.nom + ", " + point.adresse1 + ", " + point.codePostal + " " + point.localite;
                    span.style.position = "absolute";
                    span.style.fontSize = "10px";
                    header.append(br);
                    header.append(span);
                }
                jQuery('#monIdDeWidgetColissimo').frameColissimoClose();
            })

    }

    triggerWidget() {
        $('body')[0].classList.add('-active-shadow');
        $('div.shadow')[0].classList.add('-active');
        jQuery('#monIdDeWidgetColissimo').frameColissimoOpen({
            "URLColissimo": this.state.url_serveur,
            "callBackFrame": 'this.triggerWidgetCallBack',
            "ceCountry": "FR",
            "ceAddress": this.state.ceAddress,
            "ceZipCode": this.state.ceZipCode,
            "ceTown": this.state.ceTown,
            "token": this.state.token
        });
        $('html, body').animate({
            scrollTop: $("#monIdDeWidgetColissimo").offset().top - 20
        }, 1000);
    }

}
