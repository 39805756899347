import Glide from "@glidejs/glide";
// import $ from "jquery";
$(document).ready(() => {
    if(document.querySelector('#descriptionTaxon')) {
        let descriptionTaxon = document.querySelector('#descriptionTaxon');
        let contentDiv = document.querySelector("#contentTaxon")

        console.log(descriptionTaxon);
        console.log(contentDiv);


        descriptionTaxon.parentNode.insertBefore(contentDiv, descriptionTaxon)
    }
});