$(document).ready(() => {
    let Npopup = document.querySelector(".newsletter-popup");
    let Cpopup = document.querySelector(".checkout-popup");
    if (Npopup) {
        setTimeout(() => {
            if (document.cookie.indexOf("popupAlreadyOpen=true") === -1) {
                let days = 30;
                let date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

                Npopup.classList.add("-open");

                document.cookie = "popupAlreadyOpen=true; expires=" + date.toUTCString();
            }
        }, 2000)

        Npopup.querySelector(".close").addEventListener("click", function () {
            Npopup.classList.remove('-open');
        })

    }
    if (Cpopup) {
        Cpopup.querySelectorAll(".close").forEach((item) =>
            item.addEventListener("click", function () {
                Cpopup.classList.remove('-open');
            }))
    }
});
