// import $ from 'jquery'
import axios from "axios";

export default class CartUpdate {
    constructor() {
        this.state = {
            updater: null
        }
        $(document).ready(() => {
            this.init();
        })
    }

    init() {
        if (window.location.search.includes("addProduct"))
            this.openPopup();

        if ($('#sylius_cart'))
            $('.ui-input-number > span').click(() => {
                clearTimeout(this.state.updater);
                this.updater();
            })
        //

    }

    updater() {
        this.state.updater = setTimeout((e) => {
            $('#sylius_cart').submit();
        }, 2000);
    }

    openPopup() {
        let div = document.createElement('div');
        let h3 = document.createElement('h3');
        let body = $("body")[0];
        div.classList.add('popup-sucess');
        div.addEventListener('click',()=>{
            body.removeChild(div)
            window.history.pushState({}, document.title, window.location.pathname);
        })
        h3.innerHTML = $('#trans-addCart').val();
        div.appendChild(h3);
        // body.classList.add('popup-open');
        body.appendChild(div);
        setTimeout(()=>{
            body.removeChild(div)
            window.history.pushState({}, document.title, window.location.pathname);
        },2000)
    }
}
