import Glide from "@glidejs/glide";
// import $ from "jquery";
$(document).ready(() => {
    let list = document.querySelectorAll(".testimony_container_list_item");
    let container = document.getElementById('glide__slides__testimony');
    if (list.length > 0 && container) {
        if(screen.width > 768) {
            var glide = new Glide('#testimony_glide', {
                type: 'carousel',
                perView: 4,
                perSwipe: '|',
                gap: 80,
                autoplay: 5000,
            });
            glide.mount();
        }
        else{
            var glide = new Glide('#testimony_glide', {
                type: 'carousel',
                perView: 1,
                perSwipe: '|',
                gap: 80,
                autoplay: 5000,
            });
            glide.mount();
        }
    }
});